import { useModalStore } from '~/stores'

export default defineNuxtRouteMiddleware((to) => {
    // Create a reactive state and set default value
    const openSportIframe = useState('openSportIframe', () => ref(false))

    const useModalStoreInstance = useModalStore()

    if (import.meta.client) {
        const userStoreFromStorage = localStorage.getItem('userStore')
        const userStore = JSON.parse(userStoreFromStorage)
        const user = userStore?.user

        const needLogin = to.query.login
        if (!user && needLogin) {
            openSportIframe.value = false
            useModalStoreInstance.showLoginModal = true
        } else {
            openSportIframe.value = true
        }
    }
})
